body {
  margin-bottom: 200px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), url("../public/backg.png");
  background-size: 85%, 60%;
  background-repeat: no-repeat, repeat;
  background-position: center center, center center;
  background-attachment: fixed, fixed;
  z-index: -1,-100;
  background-color: rgb(0, 0, 0);
}

body::after {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  background-size: 125px;
  background-image: url("./frame2.png");
  background-repeat: repeat;
  animation: scroll 840s linear infinite;
  opacity: .5;
  z-index: -20;
  margin-bottom: 200px;
}
@keyframes scroll {
  0% {
    background-position: -3000px 3000px;
  }
  12.5% {
    background-position: 0px -3000px;
  }
  25% {
    background-position: -3000px 0px;
  }
  37.5% {
    background-position: 0px 3000px;
  }
  50% {
    background-position: -3000px 3000px;
  }
  62.5% {
    background-position: 0px -3000px;
  }
  75% {
    background-position: -3000px 0px;
  }
  87.5% {
    background-position: 0px 3000px;
  }
  100% {
    background-position: -3000px 3000px;
  }
}

body.dark {
  margin-bottom: 200px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  padding: auto;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(to right, rgba(16, 14, 22, 0.5), rgba(17, 0, 54, 0.4)), url("../public/backg.png");
  background-size: 100%, 60%;
  background-repeat: no-repeat, repeat;
  background-position: center center, center center;
  background-attachment: fixed, fixed;
  z-index: -1,-100;
  background-color: rgb(0, 0, 0);
}

body.dark::after {
  content: "";
  position: fixed;
  top: 62px;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  background-size: 125px;
  background-image: url("./frame2.png");
  background-repeat: repeat;
  animation: scroll 5000s linear infinite;
  opacity: .15;
  z-index: -20;
  margin-bottom: 200px;
}
@keyframes scroll {
  0% {
    background-position: -3000px 3000px;
  }
  12.5% {
    background-position: 0px -3000px;
  }
  25% {
    background-position: -3000px 0px;
  }
  37.5% {
    background-position: 0px 3000px;
  }
  50% {
    background-position: -3000px 3000px;
  }
  62.5% {
    background-position: 0px -3000px;
  }
  75% {
    background-position: -3000px 0px;
  }
  87.5% {
    background-position: 0px 3000px;
  }
  100% {
    background-position: -3000px 3000px;
  }
}

:root {
  --window-height: calc(100vh - 190px);
  --window-height-account: calc(100vh - 180px);
  --window-widthx00: calc(100vw - 800px);
  --window-width200: calc(100vw - 200px);
  --window-width600: calc(100vw - 600px);
  --window-height2: calc(100vh - 65px);
  --window-heightChat: calc(100vh - 245px);
  /* --color-body: #fff; */
}


h1 {
  font-weight: bold;
  text-align: center;
}

h2 {
  text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.bold {
  font-weight: 500;
}

.bolder {
  font-weight: 700;
}

.topbar{
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9900;
}

Card {
  padding: 0px;
  height: auto;
  align-items: center;
}

h1 {
  margin: 200px, 200px;
  text-align: center;
}

p1 {
margin: 200px, 200px;
font-weight: bold;
text-align: center;
}

button {
  border: 1px solid rgb(255, 255, 255);
  color: white;
  background-color: rgb(44, 41, 56);
  border-radius: 4px;
  height: 38px;
  text-align: center;
  font-size: large;
  padding: 0px 10px;
  white-space: nowrap;
}

button:hover:not(:disabled) {
  filter: brightness(1.75);
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.red {
  background-color: rgb(202, 31, 45);
}

.blackfooter {
  background: black;
  height: 20%;
  display: flex;
  justify-content: bottom;
  z-index: 1;
  opacity: .80;
}

.blackfooter2 {
  /* background: black; */
  height: 100%;
  z-index: 0;
  /* opacity: .70; */
}

.left-h1{
  text-align: left;
  margin: 30px 5px 20px 5px;
}

.left-h1-2{
  text-align: left;
  margin: 0px 5px 20px 5px;
}

.left-h2{
  text-align: left;
  margin: 4% 5px 20px 5px;
}

.left-h3{
  text-align: left;
  margin: 45px 5px 0px 5px;
}

.left {
  text-align: left;
  margin: 5px;
}

.left-top-card {
  text-align: left;
  margin: 15px 5px 15px 5px;
}

.left-top-card2 {
  text-align: left;
  margin: 15px 5px 15px 0px;
}

.white-space {
  max-width: 80%;
  margin: auto;
  background-color: transparent;
}

.navbar-brand {
  font-size: 23px;
  font-weight: 500;
}

.navbar-brand2 {
  font-size: 23px;
  font-weight: 600;
  /* margin: 0 10px; */
  margin: auto;
  padding: 0 15px;
  text-decoration: none;
  color: white;
  transition: all 0.5s;
}

.navbar-brand2:hover {
  text-decoration: none;
  color: white;
}

.nav-item {
  font-size: 20px;
  font-weight: 400;
  padding: auto;
  float: left;
  overflow: hidden;
  list-style-type: none;
}

.nav-dropdown-item {
  font-size: 20px;
  font-weight: 400;
  text-decoration: none;
  color: white;
  display: block;
  margin: 0 10px 0 10px;
}

.nav-dropdown-item:hover {
  text-decoration: none;
  color: white;
}

.semi-bold-20 {
  font-size: 20px;
  font-weight: 500;
}

.semi-bold-25 {
  font-size: 25px;
  font-weight: 500;
}

.left-div {
  width: 50%;
  float: left;
}

.right-div {
  margin-left: 50%;
}

.left-card {
  width: 40%;
  /* size: 510px; */
  margin: 0% 6% 1% 5%;
  float: left;
}

.left2 {
  text-align: left;
  margin: 3%;
}

.centered-h1 {
  text-align: center;
  margin-bottom: 3%;
}

.centered-h5 {
  text-align: center;
  font-weight: 750;
  color: var(--color-body);
  margin-top: 4%;
  margin-bottom: 0;
}

.centered-h5.dark {
  text-align: center;
  font-weight: 750;
  color: var(--color-body);
  margin-top: 4%;
  margin-bottom: 0;
}

.centered-h5-2 {
  text-align: center;
  font-weight: 750;
  color: var(--color-body);
  margin-top: 1%;
}

.centered-h5-2.dark {
  text-align: center;
  font-weight: 750;
  color: rgb(255, 255, 255);
  margin-top: 1%;
}

.label{
  text-align: left;
  margin: 15px 5px 0px 5px;
}

.label2{
  text-align: left;
  margin: 10px 5px 0px 5px;
}

.label-center{
  text-align: center;
  margin: 15px 5px 0px 5px;
}

.edit-modal {
  opacity: 0%;
  transition: all 0.5s;
}

.edit-modal:hover {
  opacity: 100%;
  transition: all 0.5s;
}

.scrollable {
  margin: 0% 0% 0% .5%;
  width: 99%;
  height: 56.5vh;
  overflow-x: hidden;
  overflow-y: auto;
  border: 1px solid rgba(0, 0, 0, 0.212);
  background-color: rgba(15, 0, 50, 0.315);
  border-radius: 2px;
  outline: 1px solid rgba(255, 255, 255, 0.3);
}

.outScrollable {
  position: relative; /* Make the container a positioning context */
  width: 100%; /* Ensure the image container takes the full width of the scrollable div */
  height: 100%; /* Ensure the image container takes the full height of the scrollable div */
  /* display: flex; */
}

.inScrollable {
  /* margin-top: -64px; */
  position: relative; /* Make the container a positioning context */
  width: 100%; /* Ensure the image container takes the full width of the scrollable div */
  height: 708px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardpool {
  margin: 15px 0;
  background-color: rgba(80, 42, 170, 0.315);
  border: 2px solid rgb(68, 17, 206);
  border-radius: 5px;
  height: 70vh;
  outline: 1px solid white;
  /* transition: all 0.5s; */
}

.no-cardpool {
  margin: 15px 0;
  background-color: rgba(117, 90, 182, 0.315);
  border: 2px solid rgb(68, 17, 206);
  border-radius: 5px;
  outline: 1px solid white;
  /* transition: all 0.5s; */
}

.maindeck {
  margin: 15px 0;
  background-color: rgba(173, 73, 73, 0.315);
  border: 2px solid rgb(173, 41, 41);
  border-radius: 5px;
  outline: 1px solid white;
}

.pluckdeck {
  margin: 15px 0;
  background-color: rgba(70, 115, 238, 0.315);
  border: 2px solid rgb(30, 41, 199);
  border-radius: 5px;
  outline: 1px solid white;
}

.maindeck2{
  margin: 15px 0;
  background-color: rgba(173, 73, 73, 0.315);
  border: 2px solid rgb(173, 41, 41);
  border-radius: 5px;
  outline: 1px solid white;
}

.back-to-top {
  height: 65px;
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  cursor: pointer;
  opacity: 100%;
  z-index: 9000;
  transition: opacity 0.5s;
}

.hidden {
  opacity: 0%;
}

.back-to-top:hover {
  opacity: 60%;
}

.no-border {
  border: none;
}

.Staunch {
  color: rgb(255, 255, 255);
  background: url("./placeh2.png"),rgba(0, 158, 92, 0.705);
  background-blend-mode: multiply;
  background-size: 100%;
  border: 7px solid rgb(3, 131, 78);
  border-radius: 7px;
  width: 207px;
  outline: 1px solid white;
}

.bigStaunch {
  color: aliceblue;
  background: url("./placeh3s.png");
  /* background-blend-mode: multiply; */
  background-size: 100%;
  border: 7px solid rgb(3, 131, 78);
  border-radius: 7px;
  margin-top: 4%;
  width: 635px;
  outline: 1px solid white;
}

.Power {
  color: aliceblue;
  background: url("./placeh2.png"),rgba(255, 0, 34, 0.788);
  background-blend-mode: multiply;
  background-size: 100%;
  border: 7px solid rgb(255, 0, 43);
  border-radius: 7px;
  width: 207px;
  outline: 1px solid white;
}

.bigPower {
  color: aliceblue;
  background: url("./placeh3p.png");
  /* background-blend-mode: multiply; */
  background-size: 100%;
  border: 7px solid rgb(255, 0, 43);
  border-radius: 7px;
  margin-top: 4%;
  width: 635px;
  outline: 1px solid white;
}

.Unity {
  color: aliceblue;
  background: url("./placeh2.png"),rgba(50, 132, 255, 0.705);
  background-blend-mode: multiply;
  background-size: 100%;
  border: 7px solid rgb(28, 40, 78);
  border-radius: 7px;
  width: 207px;
  outline: 1px solid white;
}

.bigUnity {
  color: aliceblue;
  background: url("./placeh3u.png");
  /* background-blend-mode: multiply; */
  background-size: 100%;
  border: 7px solid rgb(28, 40, 78);
  border-radius: 7px;
  margin-top: 4%;
  width: 635px;
  outline: 1px solid white;
}

.Canny {
  color: aliceblue;
  background: url("./placeh2.png"),rgba(135, 20, 211, 0.678);
  background-blend-mode: multiply;
  background-size: 100%;
  border: 7px solid rgb(60, 26, 68);
  border-radius: 7px;
  width: 207px;
  outline: 1px solid white;
}

.bigCanny {
  color: aliceblue;
  background: url("./placeh3c.png");
  /* background-blend-mode: multiply; */
  background-size: 100%;
  border: 7px solid rgb(68, 28, 78);
  border-radius: 7px;
  margin-top: 4%;
  width: 635px;
  outline: 1px solid white;
}

.NoClass {
  color: aliceblue;
  background: url("./placeh4.png"),#4d475e49;
  background-blend-mode: overlay;
  background-size: 100%;
  border: 7px solid #4D475E;
  border-radius: 7px;
  font-weight: 500;
  width: 207px;
  outline: 1px solid white;
}

.bigNoClass {
  color: aliceblue;
  background: url("./placeh5.png"),#4d475e49;
  background-blend-mode: overlay;
  background-size: 100%;
  border: 7px solid #4D475E;
  border-radius: 7px;
  font-weight: 500;
  margin-top: 4%;
  width: 635px;
  outline: 1px solid white;
}

.dcbsearch-x-large {
  width: 551px;
  height: 37px;
}

.dcbsearch-large {
  width: 366px;
  height: 37px;
}

.dcbsearch-medium {
  width: 178px;
  height: 37px;
}

.dcbsearch-small {
  width: 115px;
  height: 37px;
}

.builder-input {
  width: 370px;
  height: 37px;
  margin: 5px 5px 0px 5px;
}

.builder-text {
  width: 370px;
  height: 115px;
  margin: 5px 5px 0px 5px;
}

.cover-card {
  min-width: 338px;
  width: 20vw;
  margin: 2.5% 0px 0px 0%;
  border-radius: 17px;
  overflow: hidden;
  border: 1px solid white;
}

.builder-card {
  width: 140px;
  margin: 2.25px 0px;
  border-radius: 7px;
  overflow: hidden;
  border: 1px solid white;
}

.builder-card2 {
  width: 140px;
  margin: 2.25px 0px;
  border-radius: 7px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  border: 1px solid white;
}

.builder-card2:hover {
  position: relative;
  transform: scale(2.5);
  z-index: 2000;
  width: 140px;
  margin: 2.25px 0px;
  border-radius: 7px;
  overflow: hidden;
}

.builder-card3 {
  width: 12px;
  margin: 10px 0px;
  border-radius: 7px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  border: 1px solid white;
}

.builder-card3:hover {
  transform: scale(3.04);
  z-index: 2000;
  width: 140px;
  margin: 2.25px 0px;
  border-radius: 7px;
  overflow: hidden;
}

.selected {
  outline: 7px solid rgb(255, 0, 0);
  border: none;
}

.add-comp-button {
  margin: 15px 10px 10px 5px;
  width: 100px;
}

.create-card-text {
  width: 370px;
  height: 148px;
  margin: 5px 5px 0px 5px;
}

.cd-container {
  display: flex;
  justify-content: center;
}

.cd-container-child {
  margin-top: 40px;
}

.cd-container-child2 {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.cd-inner {
  display: flex;
  justify-content: center;
}

.cd-inner2{
  display: grid;
  /* gap: 0% 1%; */
  grid-template-columns: repeat(1, 1fr);
  justify-items: center;
}

.cd-inner3{
  display: grid;
  /* gap: 0% 1%; */
  grid-template-columns: repeat(1, 1fr);
  justify-items: center;
}

.cd-card {
  width: 480px;
  border-radius: 4%;
  overflow: hidden;
  border: 1px solid white;
}

.cd-related-card {
  width: 140px;
  border-radius: 4%;
  overflow: hidden;
  margin: 0px 10px 10px 10px;
  border: 1px solid white;
  transition: transform 0.3s ease-in-out;
}

.cd-related-card:hover {
  position: relative;
  transform: scale(2.5);
  z-index: 2000;
  width: 140px;
  border-radius: 7px;
  overflow: hidden;
}

.cd-related-modal-card {
  width: 140px;
  border-radius: 4%;
  overflow: hidden;
  margin: 0px 10px 10px 10px;
  border: 1px solid white;
}

.cd-info {
  display: grid;
  gap: 8px 5px;
  grid-template-columns: repeat(3, 1fr);
  width: 635px;
  margin: auto auto 8px auto;
  justify-items: start;
}

.button100 {
  min-width: 100px;
  margin-right: 3px;
}

.button225 {
  width: 225px;
  margin-right: 3px;
}

.card-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem; /* Add any gap you want between the cards */
}

.cards-page-card-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem; /* Add any gap you want between the cards */
}

.cards-page-card-list5 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1rem; /* Add any gap you want between the cards */
}

.card-list2 {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem; /* Add any gap you want between the cards */
}

.decks-page-card-list2 {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem; /* Add any gap you want between the cards */
}

.deck-row-card-list2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem; /* Add any gap you want between the cards */
}

.card-list3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: .25rem; /* Add any gap you want between the cards */
}

.card-list5 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: .25rem; /* Add any gap you want between the cards */
}

.card-list-card {
  width: 100%;
  margin: 20px 0px 0px 0px;
  border-radius: 4.5%;
  overflow: hidden;
  border: 1px solid white;
}

.card-list-card2 {
  width: 100%;
  margin: 0px 3px 0px 0px;
  border-radius: 4.5%;
  overflow: hidden;
  border: 1px solid white;
}

.card-list-card3 {
  width: 100%;
  /* margin: 20px 3px 0px 3px; */
  border-radius: 7px;
  overflow: hidden;
}

.card-list-card4 {
  width: 100%;
  margin: 10px 0px 0px 0px;
  border-radius: 4.5%;
  overflow: hidden;
  border: 1px solid white;
}

.img-modal {
  width: 338px;
  border-radius: 17px;
  overflow: hidden;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
}

.modal-card {
  width: 338px;
  border-radius: 17px;
  overflow: hidden;
}

.logo {
  margin: 2px 4px 4px 5px;
  width: 60px;
  z-index: -1;
}

.logo2 {
  margin: 2px 4px 4px 5px;
  width: 25px;
  height: 27px;
  z-index: 0;
}

.logo3 {
  margin: 4px 4px 4px 5px;
  width: 27px;
  height: 25px;
  z-index: 0;
}

.logo4 {
  margin: 45px 4px 4px 5px;
  width: 27px;
  height: 27px;
  z-index: 0;
}

.logo5 {
  margin: 2px 4px 4px 5px;
  width: 50px;
  height: 50px;
  z-index: 0;
  float: right;
}

.logo6 {
  margin: 2px 4px 4px 5px;
  width: 54px;
  z-index: -1;
}

.logo7 {
  margin: 4px 4px 4px 5px;
  height: 27px;
  z-index: 0;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  position: relative;
  z-index: 0;
}

.social-icon {
  margin: auto;
  width: 30px;
  margin: 2px 4px 6px 6px;
}

.no-cards {
  margin: 0px 0px 20px 20px;
}

.no-cards2 {
  margin: 0px 0px 10px 20px;
}

.imgwindow {
  height: 300px;
  width: 300px;
  margin: auto;
}

.textwindow{
  padding: 16%;
}

.undercontext {
  margin: auto;
  text-align: center;
}

.underconimg {
  margin-left: auto;
  margin-right: auto;
  width: 300px;
  overflow: hidden;
}

.bigStaunch2 {
  color: aliceblue;
  background: url("./placeh3.png"),rgba(0, 158, 92, 0.705);
  background-blend-mode: multiply;
  background-size: 70%;
  border: 7px solid rgb(3, 131, 78);
  border-radius: 7px;
  margin-top: 2%;
  margin-bottom: 2%;
  width: 100%;
  height: 100%;
  outline: 1px solid white;
}

.bigPower2 {
  color: aliceblue;
  background: url("./placeh3.png"),rgb(255, 0, 34, 0.788);
  background-blend-mode: multiply;
  background-size: 70%;
  border: 7px solid rgb(255, 0, 43);
  border-radius: 7px;
  margin-top: 2%;
  margin-bottom: 2%;
  width: 100%;
  height: 100%;
  outline: 1px solid white;
}

.bigUnity2 {
  color: aliceblue;
  background: url("./placeh3.png"),rgba(50, 132, 255, 0.705);
  background-blend-mode: multiply;
  background-size: 70%;
  border: 7px solid rgb(28, 40, 78);
  border-radius: 7px;
  margin-top: 2%;
  margin-bottom: 2%;
  width: 100%;
  height: 100%;
  outline: 1px solid white;
}

.bigCanny2 {
  color: aliceblue;
  background: url("./placeh3.png"),rgba(135, 20, 211, 0.678);
  background-blend-mode: multiply;
  background-size: 70%;
  border: 7px solid rgb(68, 28, 78);
  border-radius: 7px;
  margin-top: 2%;
  margin-bottom: 2%;
  width: 100%;
  height: 100%;
  outline: 1px solid white;
}

.bigNoClass2 {
  color: aliceblue;
  background: url("./placeh5.png"),#4d475e49;
  background-blend-mode: overlay;
  background-size: 100%;
  border: 7px solid #4D475E;
  border-radius: 7px;
  font-weight: 500;
  margin-top: 2%;
  margin-bottom: 2%;
  width: 100%;
  height: 100%;
  text-decoration: none;
  outline: 1px solid white;
}

.nav-link {
  text-decoration: none;
  color: var(--color-body);
}

.nav-link:hover {
  text-decoration: none;
  color: var(--color-body);
}

.nav-link2 {
  text-decoration: none;
  color: var(--color-body);
  padding: 0px;
}

.nav-link2:hover {
  text-decoration: none;
  color: var(--color-body);
  padding: 0px;
}

.deck-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem; /* Add any gap you want between the cards */
}

.maindeck3 {
  margin: 0%;
  background-color: rgba(163, 26, 26, 0.315);
  border: 2px solid rgb(173, 41, 41);
  border-radius: 5px;
  outline: 1px solid white;
}

.pluckdeck3 {
  margin: 0%;
  background-color: rgba(26, 63, 163, 0.315);
  border: 2px solid rgb(41, 50, 173);
  border-radius: 5px;
  outline: 1px solid white;
}

.card-container {
  position: relative;
  display: inline-block;
}

.card-container:hover img {
  display: inline-block;
}

.card-container:hover div {
  display: inline-block;
}

.card-image {
  position: absolute;
  z-index: 200;
  width: 338px;
  margin: 2.5% 0px 0px 0%;
  border-radius: 17px;
  overflow: hidden;
  top: 100%;
  left: 100%;
  display: none;
  transform: translate(10%, -80%);
  border: 1px solid white;
}

.heightNorm{
  height: 38px;
  text-align: center;
}

.bigStaunch3 {
  color: aliceblue;
  background: url("./placeh3.png"),rgba(0, 158, 92, 0.705);
  background-blend-mode: multiply;
  background-size: 70%;
  border: 7px solid rgb(3, 131, 78);
  border-radius: 7px;
  margin-top: 4%;
  width: 90%;
  outline: 1px solid white;
}

.bigPower3 {
  color: aliceblue;
  background: url("./placeh3.png"),rgb(255, 0, 34, 0.788);
  background-blend-mode: multiply;
  background-size: 70%;
  border: 7px solid rgb(255, 0, 43);
  border-radius: 7px;
  margin-top: 4%;
  width: 90%;
  outline: 1px solid white;
}

.bigUnity3 {
  color: aliceblue;
  background: url("./placeh3.png"),rgba(50, 132, 255, 0.705);
  background-blend-mode: multiply;
  background-size: 70%;
  border: 7px solid rgb(28, 40, 78);
  border-radius: 7px;
  margin-top: 4%;
  width: 90%;
  outline: 1px solid white;
}

.bigCanny3 {
  color: aliceblue;
  background: url("./placeh3.png"),rgba(135, 20, 211, 0.678);
  background-blend-mode: multiply;
  background-size: 70%;
  border: 7px solid rgb(68, 28, 78);
  border-radius: 7px;
  margin-top: 4%;
  width: 90%;
  outline: 1px solid white;
}

.bigNoClass3 {
  color: aliceblue;
  background: url("./placeh5.png"),#4d475e49;
  background-blend-mode: overlay;
  background-size: 100%;
  border: 7px solid #4D475E;
  border-radius: 7px;
  font-weight: 500;
  margin-top: 4%;
  width: 90%;
  text-decoration: none;
  outline: 1px solid white;
}

.bigFaith {
  color: aliceblue;
  background: url("./placeh5.png"),#ffbb006c;
  background-blend-mode: overlay;
  background-size: 100%;
  border: 7px solid #f0be1c;
  border-radius: 7px;
  font-weight: 500;
  margin-top: 4%;
  width: 90%;
  outline: 1px solid white;
}

.card-image-wrapper {
  position: relative;
  overflow: hidden;
  height: 200px; /* Adjust this value as needed to control the card height */
}

.card-image-clip {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-top: -20%;
  height: 50%; /* Adjust this value to show the desired portion of the image */
  background-color: black; /* Adjust the background color if needed */
}

.card-image-clip2 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-top: -25%;
  height: 50%; /* Adjust this value to show the desired portion of the image */
  background-color: black; /* Adjust the background color if needed */
}

.card-image-clip3 {
  /* position: absolute;
  top: 0;
  left: 0;
  right: 0; */
  margin-top: -20%;
  height: 50%;
  background-color: black; /* Adjust the background color if needed */
}

.card-image2 {
  width: 100%;
  height: auto;
  object-fit: cover;
  filter: brightness(20%);
}

.deck-list-background {
  background-color: rgba(0, 0, 0, 0.7); /* Adjust the background color and opacity as desired */
}

.margin-top-n1 {
  margin-top: -1px;
}

.margin-top-5 {
  margin-top: 5px;
}

.margin-top-8 {
  margin-top: 9px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.margin-bottom-40 {
  margin-bottom: 40px;
}

.margin-bottom-50 {
  margin-bottom: 50px;
}

.margin-bottom-n10 {
  margin-bottom: -10px;
}

.margin-auto {
  margin: auto;
}

.db-pool-count {
  margin: 1% 0%;
  font-weight: 700;
  cursor: pointer;
  /* color: #09001F; */
}

.db-main-count {
  margin: 1% 0%;
  font-weight: 700;
  cursor: pointer;
  /* color: #330808; */
}

.db-pluck-count {
  margin: 1% 0%;
  font-weight: 700;
  cursor: pointer;
  /* color: #081433; */
}

.light-dark {
  height: 65px;
  position: fixed;
  right: 2rem;
  bottom: 7rem;
  cursor: pointer;
  opacity: 30%;
  transition: opacity 0.5s;
  z-index: 9000;
}

.light-dark:hover {
  height: 65px;
  position: fixed;
  right: 2rem;
  cursor: pointer;
  opacity: 100%;
  transition: opacity 0.5s;
}

.aligned {
  text-align: center;
  align-items: center;
  padding: 0;
}

.create-section {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30vh;
}

.loading-spinner {
  margin-top: 3%;
  border: 4px solid rgba(0, 0, 0, 0.3);
  border-top: 4px solid #ffffff;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.pointer {
  cursor: pointer;
}

.card-row {
  width: 100%;
  min-width: 200px;
  border-radius: 13px;
  overflow: hidden;
  border: 1px solid white;
}

.rarities {
  margin: 15px 0;
  background-color: rgba(40, 34, 70, 0.315);
  border: 2px solid rgb(40, 34, 70);
  border-radius: 5px;
  outline: 1px solid white;
}

.rarities2 {
  margin: 15px 0;
  background-color: rgba(40, 34, 70, 0.315);
  border: 2px solid rgb(40, 34, 70);
  border-radius: 5px;
  height: 70vh;
  outline: 1px solid white;
}

.no-rarities {
  margin: 15px 0;
  background-color: rgba(40, 34, 70, 0.315);
  border: 2px solid rgb(40, 34, 70);
  border-radius: 5px;
  outline: 1px solid white;
}

.ultra {
  --angle: 0deg;
  display: flex;
  background-image: conic-gradient(from var(--angle), red, yellow, lime, aqua, blue, magenta, red);
  border-radius: 8px;
  width: 140px;
  height: 197px;
  margin-top: 2.5px;
  overflow: hidden;
  animation: 3s rotate linear infinite;
  transition: transform 0.3s ease-in-out;
  position: relative;
  z-index: 900;
}

  @keyframes rotate {
    to {
      --angle: 360deg;
    }
  }

  @property --angle {
    syntax: '<angle>';
    initial-value: 0deg;
    inherits: false;
  }

.ultra:hover {
  position: relative;
  transform: scale(2.5);
  z-index: 999;
  border-radius: 8px;
  width: 140px;
  height: 197px;
  margin: 2.25px 0px;
  border-radius: 7px;
  overflow: hidden;
}

.builder-card4 {
  width: 136px;
  height: 193px;
  border-radius: 7px;
  overflow: hidden;
  margin: auto;
}

.builder-card5 {
  width: 140px;
  margin: 0;
  border-radius: 7px;
  overflow: hidden;
  border: 1px solid white;
}

.rainbow {
  text-align: center;
  text-decoration: underline;
}
.rainbow_text_animated {
  background: linear-gradient(to right, red, yellow, lime, aqua, blue, magenta, red);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: rainbow_animation 6s ease-in-out infinite;
  background-size: 400% 100%;
}

.rainbow_text_animated2 {
  background: linear-gradient(to right, red,  lime,  blue, yellow, red);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: rainbow_animation 6s ease-in-out infinite;
  background-size: 400% 100%;
}

@keyframes rainbow_animation {
  0%,100% {
      background-position: 0 0;
  }

  50% {
      background-position: 100% 0;
  }
}

.small-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 7px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  min-width: 250px;
  z-index: 1000;
}

.small-modal-dark {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(33, 24, 54, 0.9);
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 7px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  min-width: 250px;
  z-index: 1000;
}

.medium-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 7px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 80%;
  z-index: 1000;
}

.medium-modal-dark {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(33, 24, 54, 0.9);
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 7px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 80%;
  z-index: 1000;
}

.medium-modal-dark2 {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(33, 24, 54, 0.9);
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 7px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 610px;
  z-index: 1000;
}

.large-modal {
  position: fixed;
  top: 70px;
  left: 50%;
  transform: translate(-50%, 0%);
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 5px;
  outline: 3px solid #ffffff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 75%;
  z-index: 1000;
  overflow-x: visible;
  overflow-y: auto;
  max-height: var(--window-height);
}

.large-modal-dark {
  position: fixed;
  top: 70px;
  left: 50%;
  transform: translate(-50%, 0%);
  background-color: rgba(33, 24, 54, 0.9);
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 75%;
  z-index: 1000;
  overflow-x: visible;
  overflow-y: auto;
  max-height: var(--window-height);
}

.black {
  color: black;
}

.blackSpace {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.507);
  width: 100vw;
  height: 100vh;
  z-index: 999;
}

.ultra2 {
  --angle: 0deg;
  display: flex;
  background-image: conic-gradient(from var(--angle), red, yellow, lime, aqua, blue, magenta, red);
  border-radius: 8px;
  width: 140px;
  height: 197px;
  margin-top: 2.5px;
  overflow: hidden;
  animation: 3s rotate linear infinite;
  transition: transform 0.3s ease-in-out;
  position: relative;
}

@keyframes rotate {
  to {
    --angle: 360deg;
  }
}

@property --angle {
  syntax: '<angle>';
  initial-value: 0deg;
  inherits: false;
}

.error {
  color: red;
  margin-top: 10px;
  margin-bottom: 0px;
  padding: 0;
  text-align: center;
}

.error2 {
  /* color: white; */
  margin: 0 0 0 5px;
  padding: 0;
  /* text-align: center; */
}

.account-split{
  display: flex;
}

.account-info-container {
  min-width: 40%;
}

.account-option-container {
  min-width: 55%;
}

.account-option-items {
  margin-top: 20px;
  width: 47.5vw;
}

.account-option-item {
  width: 100%;
  filter: brightness(1);
}

.username {
  color: white;
  text-decoration: none;
  margin-right: 15px;
}

.username2 {
  color: white;
  text-decoration: none;
}

.footer-copy-right {
  font-weight: 500;
  margin-top: 12px;
}

.card-pool-fill {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  gap: 10px;
  grid-auto-flow: dense;
}

.card-pool-fill2 {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  gap: 10px;
  grid-auto-flow: dense;
  /* margin-right: 18px; */
  margin: 0px 18px 18px 18px;
  opacity: 1;
  /* max-height: 2000px; */
  transition: all 0.5s;
}

.card-pool-fill3 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow: dense;
  /* margin-right: 18px; */
}

.card-pool-fill4 {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  gap: 10px;
  grid-auto-flow: dense;
  /* margin-right: 18px; */
  /* justify-self: center; */
  margin: 10px;
  opacity: 1;
  /* max-height: 2000px; */
  transition: all 0.5s;
}

.card-page-fill {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
  gap: 10px;
  grid-auto-flow: dense;
}

.list-page-fill {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(480px, 1fr));
  gap: 10px;
  grid-auto-flow: dense;
}

.app{
  margin-top: 90px;
}

.content {
  min-height: var(--window-height);
}

.account-scrollable {
  margin: 0% 0% 0% .5%;
  height: 473px;
  overflow-x: hidden;
  overflow-y: auto;
  border: 1px solid rgba(0, 0, 0, 0.212);
  background-color: rgba(15, 0, 50, 0.315);
  border-radius: 2px;
  outline: 1px solid rgba(255, 255, 255, 0.3);
}

.half {
  opacity: 60%;
}

.half2 {
  opacity: 50%;
}

.selected2 {
  outline: 5px solid rgb(255, 255, 255);
}

.glow {
  filter: brightness(1);
}

.glow:hover {
  filter: brightness(2);
}

.glow2 {
  filter: brightness(1);
}

.glow2:hover {
  filter: brightness(1.5);
}

.glow3 {
  filter: brightness(1);
}

.glow3:hover {
  filter: brightness(1.3);
}

  /* @keyframes pulse-glow3 {
    0% {
      filter: brightness(1.3);
    }
    50% {
      filter: brightness(1);
    }
    100% {
      filter: brightness(1.3);
    }
  } */

.top-row {
  min-width: 200px;
  width: 15vw;
}

.dcbsearch-x-x-large {
  width: 740px;
  height: 37px;
}

.button-fill {
  display: flex;
}

.back-button {
  width: 67px;
  margin: 5px 5px 5px 5px;
}

.between-space {
  display: flex;
  justify-content: space-between;
}

.wide40 {
  width: 40%;
}

.wide55 {
  width: 55%;
}

.margin-left-3P {
  margin-left: 3%;
}

.deck-import {
  margin: 15px 0;
  background-color: rgba(0, 255, 200, 0.315);
  border: 2px solid rgb(0, 99, 77);
  border-radius: 5px;
  outline: 1px solid white;
}

.deck-import-scrollable {
  margin: auto;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  width: 99%;
  min-height: 20px;
  border: 1px solid rgba(0, 0, 0, 0.212);
  background-color: rgba(15, 0, 50, 0.315);
  border-radius: 2px;
  outline: 1px solid rgba(255, 255, 255, 0.3);
}

.black-white {
  color: var(--color-body)
}

.black-white:hover {
  color: var(--color-body)
}

.table200 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  margin: 0;
  border: 1px solid;
  border-color: var(--color-body);
  padding: 0px;
  background-color: white;
}

.dark .table200 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  margin: 0;
  border: 1px solid;
  border-color: var(--color-body);
  padding: 0px;
  background-color: rgba(0, 0, 0, 0.6);
}

.tableText {
  border: 1px solid;
  border-color: var(--color-body);
  padding: 0px;
  width: calc(100% - 200px);
  background-color: rgba(255, 255, 255, 0.7);
}

.dark .tableText {
  border: 1px solid;
  border-color: var(--color-body);
  padding: 0px;
  width: calc(100% - 200px);
  background-color: rgba(0, 0, 0, 0.4);
}

.text-table {
  font-weight: 600;
  margin: 5px;
}

.text-table-2 {
  font-weight: 600;
  margin: 12px;
}

.support {
  margin: 15px 0;
  background-color: rgba(73, 173, 95, 0.315);
  border: 2px solid rgb(41, 173, 81);
  border-radius: 5px;
  outline: 1px solid white;
}

.anti_support {
  margin: 15px 0;
  background-color: rgba(238, 70, 70, 0.315);
  border: 2px solid rgb(199, 30, 30);
  border-radius: 5px;
  outline: 1px solid white;
}

.large-article {
  height: 300px;
  width: 100%;
}

.cat-label {
  text-align: left;
  margin: 1% 0%;
  font-weight: 700;
}

.no-pad {
  padding: 0px;
}

.margin-top-63 {
  margin-top: 63px;
}

.margin-top-93 {
  margin-top: 93px;
}

.margin-left {
  margin-left: 20px;
}

.margin-bottom-0 {
  margin-bottom: 0px;
}

.navbar {
  top: 0;
  height: 66px;
  width: 100%;
  padding: 0;
  background-color: rgb(44, 41, 56);
  border-bottom: 1px white solid;
  /* border-bottom: none */
  position: fixed;
}

.navbar.dark {
  top: 0;
  height: 62px;
  width: 100vw;
  background-color: rgb(44, 41, 56);
  border-bottom: 1px white solid;
}

.threebars {
  height: 40px;
  margin-right: 15px;
  cursor: pointer;
}

.nav-main {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.nav-button100 {
  margin: 0;
  padding: 0;
}

.animate {
  opacity: 1;
  max-height: 1000px;
  transition: opacity 0.5s, max-height 0.5s;
}

.animate2 {
  opacity: 1;
  max-height: 1000px;
  transition: opacity 0.5s, max-height 0.5s;
}

.wide90p {
  width: 90%;
}

.navbar-menu {
  display:flex;
  margin: auto;
  padding: 0 0 0 10px;
  list-style-type: none;
}

.navbar-menu-item {
  /* display:block; */
  margin: auto;
  padding: 0 10px 0 10px;
  font-weight: 400;
  color: white;
}

.nav-dropdown-content {
  background-color: rgb(80, 74, 102);
  position: absolute;
  min-width: 160px;
  z-index: 9999;
  top: 66px;
}

.navbar-select {
  height: 62px;
  width: auto;
  display: flex;
  align-items: center;
  transition: all 1s;
}

.navbar-select:hover {
  background-color: rgb(123, 114, 158);
}

.navbar-selected {
  height: 62px;
  width: auto;
  display: flex;
  align-items: center;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    background-color: rgb(123, 114, 158);
  }
  50% {
    background-color: rgb(44, 41, 56);
  }
  100% {
    background-color: rgb(123, 114, 158)
  }
}

.dropdown-select {
  display: flex;
  align-items: center;
  height: 50px;
  transition: all 1s;
}

.dropdown-select:hover {
  background-color: rgb(180, 166, 233);
  transition: all 1s;
}

.accountbuttons {
  display: flex;
  margin-right: 10px;
}

.hidden2 {
  display: none;
}

.hidden3 {
  /* opacity: 0; */
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s;
  /* display: none; */
}

.cd-title {
  font-size: 40px;
  white-space: nowrap;
}

.cd-title2 {
  font-size: 35px;
  white-space: nowrap;
}

.cd-title-container {
  width: 635px;
  display: flex;
  justify-content: center;
}

.cd-measure {
  width: fit-content;
}

.stat-item {
  width: 145px;
  margin: auto;
}

.deckSelect {
  position: absolute;
}

.deckSelect2 {
  position: absolute;
  top: 160px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.playerTabBottom {
  /* position: absolute; */
  height: 40px;
  width: 100%;
  background-color: #4d475e9a;
  outline: 1px white solid;
  border-radius: 0 0 7px 7px;
  margin: 0;
  top: 42px;
}

.playerTabTop {
  /* position: absolute; */
  height: 40px;
  width: 280px;
  background-color: #000000b7;
  outline: 1px white solid;
  margin: 0;
  /* top: 90px; */
}

.playerTabBottom2 {
  /* position: absolute; */
  height: 40px;
  width: 100%;
  background-color: #4d475e9a;
  outline: 1px white solid;
  border-radius: 0 0 7px 7px;
  margin: 0;
  top: 42px;
}

.playerTabTop2 {
  /* position: absolute; */
  height: 40px;
  width: 280px;
  background-color: #000000b7;
  outline: 1px white solid;
  margin: 0;
  /* top: 90px; */
}

.playerTabTitle2 {
  padding: 0 1px 0 10px;
  margin: 0;
}

.playerTabTitle2 {
  padding: 0 7px 0 5px;
  margin: 0;
}

.playersRow {
  width: 100%;
  height: max-content;
  position: absolute;
}

.deckSelect3 p {
  margin: 3px 0;
}

.play-area {
  height: 65vh;
  margin-top: 50px;
  margin-right: -200px;
  /* margin-bottom: 100px; */
}

.play-area-opp {
  height: 400px;
  transform: scale(0.5);
  transform-origin: top;
  margin-left: -40px;
}

.matCard {
  height: 196px;
  width: 140px;
  outline: 2px solid white;
  background-color: rgba(0, 0, 0, 0.75);
  position: relative;
  z-index: 2;
}

.matCard:hover {
  height: 196px;
  width: 140px;
  animation: pulse2 1.5s infinite;
}

.matToggle {
  display: flex;
  height: 196px;
  width: 140px;
  position: relative;
  justify-content: center;
  align-items: center;
  outline: 2px solid white;
}

.matCardSelect {
  height: 196px;
  width: 140px;
  outline: 2px solid white;
  background-color: rgba(0, 0, 0, 0.75);
  position: relative;
  animation: pulse2 1.5s infinite;
  cursor: pointer;
}

.matCardOverlay {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 196px;
  width: 140px;
  outline: 1px solid rgb(255, 255, 255);
  background-color: rgba(0, 0, 0, 0.486);
  z-index: 3;
  position: absolute;
}

.matLabel {
  height: 40px;
  width: 140px;
  outline: 2px solid rgb(255, 255, 255);
  background-color: rgba(107, 107, 107, 0.75);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.matLabel2 {
  height: 40px;
  width: 560px;
  outline: 2px solid rgb(255, 255, 255);
  background-color: rgba(107, 107, 107, 0.75);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .field_box {
  transform: perspective(1000px) rotateX(45deg) scale(.8, .8) translate(150px 20px);
  align-self: center;
  position: relative;
} */

.slider_container {
  height: 80px;
}

.lock {
  height: 30px;
  width: auto;
  margin: 15px 5px 15px 5px;
}

.settings_container {
  position: absolute;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  overflow: hidden;
  height: 442px;
  width: 110px;
  border-radius: 17px;
  border: 1px solid white;
  background-color: rgba(0, 0, 0, 0.603);
  transition: all 0.5s ease-in-out;
}

.settings_container_hide {
  position: absolute;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  height: 70px;
  width: 110px;
  border-radius: 17px;
  border: 1px solid white;
  background-color: rgba(0, 0, 0, 0.603);
  transition: all 0.5s ease-in-out;
}

/* .settings_container {
  position: relative;
  margin-top: 15vh;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  overflow: hidden;
  height: 442px;
  width: 110px;
  border-radius: 17px;
  border: 1px solid white;
  background-color: rgba(0, 0, 0, 0.603);
  transition: all 0.5s ease-in-out;
}

.settings_container_hide {
  position:relative;
  margin-top: 15vh;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  height: 70px;
  width: 110px;
  border-radius: 17px;
  border: 1px solid white;
  background-color: rgba(0, 0, 0, 0.603);
  transition: all 0.5s ease-in-out;
} */

.inner_container {
  width: 110px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  height: 442px;
  transition: all 0.3s ease-in-out;
}

.inner_container_hide {
  width: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  overflow: hidden;
  max-height: 0;
  transition: all 0.3s ease-in-out;
}

.size_button {
  /* background-color: rgba(255, 255, 255, 0); */
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  width: 30px;
  height: 30px;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select:none;
}

.size_button:hover {
  cursor: pointer;
  /* background-color: #ccc; */
}

.translate_button_container {
  margin: 10px;
}

.vertical_container {
  display: flex;
  justify-content: center;
}

.horizontal_container {
  display: flex;
  justify-content: space-between;
}

.translate_button {
  display: flex;
  /* background-color: white; */
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select:none;
  margin: 5px;
}

.translate_button:hover {
  cursor: pointer;
  /* background-color: #ccc; */
}

.utf-symbol {
  color: rgb(255, 255, 255);
  font-size: 20px;
}

.utf-symbol2 {
  color: rgb(255, 255, 255);
  font-size: 40px;
}

.volume {
  cursor: pointer;
  margin-top: 20px;
  width: 40px;
  height: 40px;
}

.flex {
  display: flex;
}

.flex-items {
  display: flex;
  align-items: center;
}

.flex-content {
  display: flex;
  justify-content: center;
}

.flex-full {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-left-media-center {
  display: flex;
  justify-content: left;
}

.builder-card-hand {
  width: 115px;
  height: 160px;
  margin-right: -30px;
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid white;
  z-index: 0;
  position: relative;
}

.builder-card-hand:hover {
  z-index: 20;
}

.builder-card-hand2 {
  width: 115px;
  height: 160px;
  margin-right: -30px;
  margin-top: -100px;
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid white;
  z-index: 0;
}

.selected3 {
  animation: pulse3 1.5s infinite;
}

.selected4 {
  background-color: rgb(170, 0, 0);
  /* animation: pulse3 5s infinite; */
  transition: all 0.5s;
}

.card-pool-fill-hand {
  display: flex;
  justify-content: center;
  margin-top: -50px;
}

.infoPanel {
  margin-top: -1px;
  height: var(--window-height2);
  width: 448px;
  background-color: rgba(0, 0, 0, 0.603);
  border: 1px solid white;
  position: fixed;
  transition: all 0.5s ease-in-out;
  z-index: 10;
}

.infoPanelClosed {
  margin-top: -1px;
  height: var(--window-height2);
  width: 40px;
  background-color: rgba(0, 0, 0, 0.603);
  border: 1px solid white;
  position: fixed;
  transition: all 0.5s ease-in-out;
  z-index: 10;
  padding-left: 13px;
}

.panel-card {
  width: 230px;
  margin: 20px 0 10px 0;
  border-radius: 4%;
  overflow: hidden;
  border: 1px solid white;
}

.panel-card2 {
  max-width: 230px;
  margin: 5px 0 10px 0;
  border-radius: 4%;
  overflow: hidden;
  border: 1px solid white;
}

.panel-text-box {
  margin: 10px;
  height: 175px;
  width: 400px;
  overflow-y: scroll;
  outline: 1px solid white;
}

.panel-text-box-text {
  margin: 0 0 0 5px;
}

.m-l-r-5 {
  margin: 0 5px 0 5px;
}

.margin-left-13 {
  margin-left: 13px;
}

.margin-left-10p {
  margin-left: 10%;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.white {
  color: white;
}

.panel-open {
  font-size: 100px;
  position: absolute;
  left: 11px;
  top:222px;
  transition: all 0.5s;
}

.panel-close {
  font-size: 100px;
  position: absolute;
  left: 360px;
  top:222px;
  transition: all 0.5s;
}

.field_box_modal {
  transform: perspective(1000px) rotateX(360deg) scale(1, 1) translate(0px 0px);
  align-self: center;
  position: absolute;
}

.sim-modal {
  position: fixed;
  color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.603);
  padding: 20px;
  border-radius: 5px;
  outline: 3px solid #ffffff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: fit-content;
  z-index: 1000;
  overflow-x: visible;
  overflow-y: auto;
  max-height: 80vh;
  opacity: 10%;
  transition: all 0.5s;
}

.sim-modal2 {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color:rgba(0, 0, 0, 0.603);
  padding: 20px;
  border-radius: 5px;
  outline: 3px solid #ffffff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: fit-content;
  z-index: 1000;
  overflow-x: visible;
  overflow-y: auto;
  max-height: 80vh;
  opacity: 10%;
  transition: all 0.5s;
}

.sim-modal:hover {
  opacity: 100%;
  transition: all 0.5s;
}

.sim-modal2:hover {
  opacity: 100%;
  transition: all 0.5s;
}


.sim-modal3 {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(33, 24, 54, 0.95);
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 7px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: fit-content;
  z-index: 1000;
  overflow-x: visible;
  overflow-y: auto;
  max-height: 80vh;
}

.margin-5 {
  margin: 5px;
}

.margin-10 {
  margin: 10px;
}

.margin-20 {
  margin: 20px;
}

.margin-5p {
  margin: 5%;
}

.card-menu {
  width: 115px;
  position: relative;
  margin-bottom: 10px;
  margin-top: -160px;
  margin-right: -30px;
  z-index: 3;
}

.deck-menu {
  width: 140px;
  position: relative;
  margin-bottom: 10px;
  margin-top: -110px;
  z-index: 3;
}

.deck-menu2 {
  width: 140px;
  position: relative;
  margin-top: -25px;
  margin-left: 10px;
  z-index: 3;
}

.deck-menu3 {
  width: 140px;
  position: relative;
  margin-top: -75px;
  margin-left: 10px;
  z-index: 3;
}

.deck-menu2Items {
  width: 140px;
  position: relative;
  margin-top: -50px;
  margin-left: 10px;
  z-index: 3;
}

.deck-menu4Items {
  width: 140px;
  position: relative;
  top: 0;
  margin-top: -50px;
  margin-left: 10px;
  margin-bottom: -50px;
  z-index: 3;
}

.deck-menu5Items {
  width: 140px;
  position: relative;
  top: 0;
  margin-top: -75px;
  margin-left: 10px;
  margin-bottom: -50px;
  z-index: 3;
}

.discard-menu {
  width: 140px;
  position: relative;
  margin-bottom: 10px;
  margin-top: -35px;
  z-index: 3;
}

.zone-menu {
  width: 140px;
  background-color: rgba(107, 107, 107, 0.75);
  position: relative;
  margin-bottom: 10px;
  margin-top: -160px;
  margin-right: -30px;
  z-index: 3;
}

.zone-menu2 {
  width: 140px;
  background-color: rgba(107, 107, 107, 0.75);
  position: relative;
  margin-bottom: 10px;
  margin-top: -135px;
  margin-right: -30px;
  z-index: 3;
}

.card-menu-item {
  width: 100%;
  height: 25px;
  background-color: rgba(107, 107, 107, 0.75);
  outline: 1px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.card-menu-item p {
  font-size: 14px;
  margin: 0;
}

.card-menu-item:hover {
  background-color: rgba(180, 180, 180, 0.75);
}

.infoPanelText {
  font-size: 25px;
  font-weight: 500;
  color: white;
  margin-bottom: 0;
  margin-left: 13px;
}

.infoPanelTextSm {
  font-size: 20px;
  font-weight: 500;
  color: white;
  margin-bottom: 0;
  margin-left: 13px;
}

.notify {
  animation: pulse4 1.5s infinite ;
}

.fontSize60 {
  font-size: 60px;
}

.fontSize70 {
  font-size: 70px;
}

.fontSizexx {
  font-size: 24px;
}

.promptBar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100px;
  position: absolute;
  overflow-x: hidden;
  top: 200px;
  background-color: rgba(0, 0, 0, 0.733);
  z-index: 9;
  transition: all 0.5s;
}

.promptBar h1{
  animation: slide 3s infinite;
}

.noPromptBar {
  width: 100vw;
  height: 0;
  position: absolute;
  top: 200px;
  background-color: rgba(0, 0, 0, 0);
  z-index: -9;
  transition: all 0.5s;
}

.chatPanel {
  margin-top: -1px;
  height: var(--window-height2);
  width: 415px;
  background-color: rgba(0, 0, 0, 0.603);
  border: 1px solid white;
  position: relative;
  transition: all 0.5s;
  z-index: 900;
}

.chatPanelClosed {
  margin-top: -1px;
  height: var(--window-height2);
  width: 40px;
  background-color: rgba(0, 0, 0, 0.603);
  border: 1px solid white;
  position: relative;
  transition: all 0.5s;
  z-index: 10;
}

.margin-left-none {
  margin-left: 0;
}

.margin-left-3 {
  margin-left: 3px;
}

.margin-left-13 {
  margin-left: 13px;
}

.margin-left-10p {
  margin-left: 10%;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.white {
  color: white;
}

.open-close-container {
  height: 100%;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(44, 41, 56, 0.685);
}

.open-close-container2 {
  height: 100%;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-panel-open {
  position: absolute;
  /* right: 11px; */
  /* top:222px; */
  width: 10px;
  transition: all 0.5s;
}

.chat-panel-close {
  position: absolute;
  /* right: 375px; */
  /* top:222px; */
  width: 10px;
  transition: all 0.5s;
  z-index: 1000;
}

.rightSimSide {
  display: flex;
  justify-content: center;
  width: fit-content;
  position: fixed;
  right: 0;
  z-index: 2;
}

.rightSimSide2 {
  display: flex;
  justify-content: center;
  width: fit-content;
  position: absolute;
  z-index: 1;
}

.scrollableChat {
  /* background-color: white; */
  height: var(--window-heightChat);
  /* height: 500px; */
  width: 368px;
  /* margin-right: -40px; */
  margin: 18px -40px 0 -5px;
  overflow-y: scroll;
  outline: white 1px solid;
  flex-wrap: wrap;
}

.chatTextBox {
  background-color: white;
  color: #000;
  width: 370px;
  height: 135px;
  margin: 10px -40px 0 -6px;
  z-index: inherit;
}

.right {
  position: relative;
  right: 0;
  margin: 0 0 0 40px;
  padding: 0;
  width: 360px;
}

.rightPanel {
  position: relative;
  right: 0;
  margin: 0 0 0 15px;
  padding: 0;
  width: 360px;
}

.messageWrapper {
  white-space: wrap;
  word-wrap: break-word;
}

.outScrollableSim {
  position: relative; /* Make the container a positioning context */
  height: 350px; /* Ensure the image container takes the full height of the scrollable div */
  /* display: flex; */
  width: 45vw;
}

.outScrollableSim2 {
  position: relative; /* Make the container a positioning context */
  height: 60vh; /* Ensure the image container takes the full height of the scrollable div */
  /* display: flex; */
  width: 45vw;
}

@keyframes pulse2 {
  0% {
    background-color: rgba(255, 255, 255, 0.60);
  }
  50% {
    background-color: rgba(0, 0, 0, 0.75);
  }
  100% {
    background-color: rgba(255, 255, 255, 0.60);
  }
}

@keyframes pulse3 {
  0% {
    filter: brightness(1.5);
  }
  50% {
    filter: brightness(0.5);
  }
  100% {
    filter: brightness(1.5);
  }
}

@keyframes pulse4 {
  0% {
    filter: brightness(1.5);
    background-color: rgba(255, 255, 255, 0.171);
  }
  50% {
    filter: brightness(0.5);
    background-color: rgba(255, 255, 255, 0);
  }
  100% {
    filter: brightness(1.5);
    background-color: rgba(255, 255, 255, 0.171);
  }
}

@keyframes pulse5 {
  0% {
    background-color: rgba(255, 255, 255, 0.2);
  }
  50% {
    background-color: rgba(0, 0, 0, 0.75);
  }
  100% {
    background-color: rgba(255, 255, 255, 0.2);
  }
}

@keyframes slide {
  0% {
    transform: translate(-1500px, 0);
  }
  10% {
    transform: translate(-1500px, 0);
  }
  25% {
    transform: translate(0, 0);
  }
  75% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(1500px, 0);
  }
}

.cardpool2-ro {
  margin: 0px 0 14px 30px;
  background-color: rgba(80, 42, 170, 0.315);
  border: 2px solid rgb(68, 17, 206);
  border-radius: 5px;
  height: 708px;
  width: 70vw;
  outline: 1px solid white;
  transition: all 0.5s;
}

.no-cardpool-ro {
  margin: 12px 0 14px 30px;
  background-color: rgba(117, 90, 182, 0.315);
  border: 2px solid rgb(68, 17, 206);
  border-radius: 5px;
  height: 56px;
  width: 70vw;
  outline: 1px solid white;
  transition: all 0.5s;
}

.no-cardpool2-ro {
  margin: 12px 0 14px 30px;
  background-color: rgba(117, 90, 182, 0.315);
  border: 2px solid rgb(68, 17, 206);
  border-radius: 5px;
  height: 95px;
  width: 70vw;
  outline: 1px solid white;
  transition: all 0.5s;
}

.no-cardpool3-ro {
  margin: 0px 0 14px 30px;
  background-color: rgba(117, 90, 182, 0.315);
  border: 2px solid rgb(68, 17, 206);
  border-radius: 5px;
  height: 708px;
  width: 100%;
  outline: 1px solid white;
  transition: all 0.5s;
}

.scrollable2 {
  margin: 0 auto 0 auto;
  width: 99%;
  height: 600px;
  overflow-x: hidden;
  overflow-y: auto;
  border: 1px solid rgba(0, 0, 0, 0.212);
  background-color: rgba(15,0,50,.315);
  border-radius: 2px;
  outline: 1px solid rgba(255, 255, 255, 0.3);
}

.dd-button-row {
  overflow-x: auto;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  position: relative;
  z-index: 1;
}

.dd-button-row2 {
  overflow-x: auto;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  position: relative;
  z-index: 1;
  padding-left: 1px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.dd-button-row::-webkit-scrollbar {
  display: none;
}

.in-hand {
  display: flex;
  justify-content: center;
  transition: all 0.5s;
}

.in-hand:hover {
  display: flex;
  justify-content: center;
  transform: translate(0, -40px);
  transition: all 0.5s;
}

.healthTracker {
  width: 125px;
  height: 37px;
  margin: 11px 0 0 10px;
}

.healthTracker2 {
  width: 90px;
  height: 37px;
  margin: 11px 0 0 0;
  border-radius: 0;
}

.healthTracker3 {
  width: 90px;
  height: 37px;
  margin: 11px 0 0 2px;
  border-radius: 0 5px 5px 0;
}

.healthTracker4 {
  width: 150px;
  height: 37px;
  margin: 5px 0 0 2px;
  border-radius: 0;
}

.healthTracker5 {
  width: 90px;
  height: 37px;
  margin: 5px 0 0 2px;
}

.front-button {
  border-radius: 5px 0 0 5px;
}

.middle-button {
  border-radius: 0;
}

.end-button {
  border-radius: 0 5px 5px 0;
}

.catTable {
  margin-left: 20px;
}

.fullTableBorder {
  border: 2px solid;
  border-color: var(--color-body);
  width: 100%;
  overflow: hidden;
  z-index: 2;
}

.maskContainer {
  height: 196px;
  width: 140px;
  z-index: 3;
  position: absolute;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.mask {
  z-index: -3;
  animation: slide2 infinite 2s;
}

@keyframes slide2 {
  0% {
    transform: translate(-100%, 0);
  }
  100% {
    transform: translate(100%, 0);
  }
}

.wide100-2 {
  width: 100vw;
}

.wide100-3 {
  width: 100%;
}

.newsRow {
  max-height: 250px;
  overflow-y: scroll;
}

.newsItem {
  width: calc(100% - 8px);
  min-height: 70px;
  margin: 10px 0px 10px 4px;
  border: 5px solid;
  border-color: white;
  border-radius: 25px 0px;
  outline: 2px solid white;
  filter: brightness(90%);
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.20), rgba(0, 0, 0, 0.4));
}

.newsText {
  margin: 0 10px;
  color: white;
}

.newsRow::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.newsRow::-webkit-scrollbar-thumb {
  background: rgb(81, 76, 104);
  border-radius: 5px;
  width: 100%;
}

.newsSection {
  height: 55px;
  margin: 0 -10px 0 -20px;
}

.newsSection2 {
  margin: 10px 2.5%;
}

.newsText2 {
  font-size: 20px;
}

.newsText3 {
  font-size: 20px;
  text-align: center;
  margin: 15px 0;
  font-weight: 500;
}

.newsText4 {
  font-size: 36px;
  margin-bottom: 0;
}

.newsText5 {
  font-size: 27px;
  margin-bottom: 0;
}

.newsText6 {
  margin: 10px;
  color: white;
}

.no-wrap {
  white-space: nowrap;
}

.simulator {
  position: absolute;
  top: 66px;
  width: max-content;
  height: auto;
}

.newsImageContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 10px;
  grid-auto-flow: dense;
  margin: 10px;
}

.newsImageContainer2 {
  margin: 10px;
}

.newsImage {
  max-height: 450px;
  max-width: 100%;
  margin: 2.25px 0px;
  border-radius: 14px;
  overflow: hidden;
  /* border: 1px solid white; */
  align-self: center;
}

.ellipsis {
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: calc(90%); /* The trick is here! */
}

.ellipsis2 {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: calc(90%); /* The trick is here! */
  padding-bottom: 2px;
}

.sim-back-wrapper {
  position: relative;
  overflow: hidden;
  height: 200px; /* Adjust this value as needed to control the card height */
  width: 100%;
  outline: 1px white solid;
  border-radius: 7px;
}

.sim-back {
  position: relative;
  overflow: hidden;
  height: 200px; /* Adjust this value as needed to control the card height */
  background-image: url("./net.png");
  background-size: 100%;
}

.sim-back::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  background-size: 1200px;
  background-image: url("./frames.png");
  background-repeat: repeat;
  animation: scroll2 1000s linear infinite;
  opacity: .175;
  z-index: 2;
}

@keyframes scroll2 {
  0% {
    background-position: 3000px 0px;
  }
  50% {
    background-position: 3000px 3000px;
  }
  100% {
    background-position: 3000px 0px;
  }
}

.sim-row-link {
  text-decoration: none;
  color: white;
}

.sim-row-link:hover {
  text-decoration: none;
  color: white;
}

.sim-row-text {
  z-index: 10;
  font-size: 60px;
}

.card-image-clip4 {
  margin-top: -20%;
  height: 50%;
  background-color: black; /* Adjust the background color if needed */
}

.cardSheet {
  height: 1080px;
  width: 792px;
  /* display: grid;
  grid-template-columns: repeat(auto-fill, minmax(336px, 240px));
  gap: 0;
  grid-auto-flow: dense; */
}

.skill_level {
  height: 55px;
  margin: 0 -22px 0 -20px;
}

.greyScale {
  filter: grayscale(1);
}

.greyScale:hover {
  filter: grayscale(1);
}

.card-image3 {
  position: absolute;
  z-index: 200;
  width: 338px;
  margin: 2.5% 0px 0px 0%;
  border-radius: 17px;
  overflow: hidden;
  top: 100%;
  left: 50%;
  display: none;
  transform: translate(10%, -80%);
  border: 1px solid white;
}

.margin-left-20 {
  margin-left: 20px;
}

.margin-0 {
  margin: 0 0 0 0;
}

.error3 {
  text-align: left;
  color: red;
  margin-top: 10px;
  margin-bottom: 0px;
  padding: 0;
}

.media-h1-h2-box {
  margin-top: -64px;
}

.flex-items-down-10-10 {
  display: inline-block;
  margin: 15px 0;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
  width: auto;
}

.hidden4 {
  display: none;
}

.space-around {
  display: flex;
  justify-content: space-around;
}

.neg-margin-top-10 {
  margin-top: -10px;
}

.margin-top-none {
  margin-top: 0;
}

.max-height{
  height: max-content;
}

.auto-complete-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 366px;
  margin: 0 5px;
}

.auto-complete-large {
  background-color: white;
  max-height: 200px;
  border: #000 solid 1px;
  position: absolute;
  z-index: 100;
  overflow-y: scroll;
  transform: translate(0, 44px);
}

/* Hide the scrollbar */
.auto-complete-large::-webkit-scrollbar {
  width: 0; /* Set the width of the scrollbar to 0 */
}

/* Hide the scrollbar thumb */
.auto-complete-large::-webkit-scrollbar-thumb {
  background-color: transparent; /* Set the background color of the thumb to transparent */
}

.auto-complete-item {
  height: 25px;
  padding-left: 3px;
}

.auto-complete-item:hover {
  border: #000 solid 1px;
}

.sim-scrollbar::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.sim-scrollbar::-webkit-scrollbar-thumb {
  background: rgb(81, 76, 104);
  border-radius: 5px;
  width: 100%;
}

.mediaInfoPanel {
  display: none;
}

.flex-column-full {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-column-items {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rollTracker {
  margin: 5px 0 0 3px;
  height: 36px;
  outline: 1px white solid;
  background-color: #00000038;
}

.padding-bottom-30 {
  padding-bottom: 30px;
}

.bigStaunch5 {
  color: aliceblue;
  background: url("./placeh3.png"),rgba(0, 158, 92, 0.705);
  background-blend-mode: multiply;
  background-size: 70%;
  border: 7px solid rgb(3, 131, 78);
  border-radius: 7px;
  margin-top: 2%;
  margin-bottom: 2%;
  width: 100%;
  height: 100%;
  outline: 1px solid white;
}

.bigPower5 {
  color: aliceblue;
  background: url("./placeh3.png"),rgb(255, 0, 34, 0.788);
  background-blend-mode: multiply;
  background-size: 70%;
  border: 7px solid rgb(255, 0, 43);
  border-radius: 7px;
  margin-top: 2%;
  margin-bottom: 2%;
  width: 100%;
  height: 100%;
  outline: 1px solid white;
}

.bigUnity5 {
  color: aliceblue;
  background: url("./placeh3.png"),rgba(50, 132, 255, 0.705);
  background-blend-mode: multiply;
  background-size: 70%;
  border: 7px solid rgb(28, 40, 78);
  border-radius: 7px;
  margin-top: 2%;
  margin-bottom: 2%;
  width: 100%;
  height: 100%;
  outline: 1px solid white;
}

.bigCanny5 {
  color: aliceblue;
  background: url("./placeh3.png"),rgba(135, 20, 211, 0.678);
  background-blend-mode: multiply;
  background-size: 70%;
  border: 7px solid rgb(68, 28, 78);
  border-radius: 7px;
  margin-top: 2%;
  margin-bottom: 2%;
  width: 100%;
  height: 100%;
  outline: 1px solid white;
}

.card-pool-fill5 {
  display: flex;
  flex-wrap: wrap;
}

.hide-show {
  font-weight: 500;
  margin: 5px 15px 5px 5px;
  cursor: pointer;
}

.underline {
  text-decoration: underline;
}

.top-bottom-border {
  border-top: 2px solid --color-body;
  border-bottom: 2px solid --color-body;
}

.linkedText {
  color: var(--color-body);
  font-weight: 500;
}

.linkedText:hover {
  color: var(--color-body);
}

.bigNoClassCreate {
  color: aliceblue;
  background-color:#2b2835;
  background-blend-mode: overlay;
  background-size: 100%;
  border: 7px solid #4D475E;
  border-radius: 7px;
  font-weight: 500;
  width: 635px;
  outline: 1px solid white;
}

.flex-baseline {
  display: flex;
  align-items: baseline;
}

.flex-column-split {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.darkModeFix {
  background-color: transparent; /* Ensure it's not white */
  position: relative; /* Adjust if necessary */
  z-index: 1; /* Adjust if necessary */
}

.darkModeFix2 {
  /* background-color: transparent; */
  position: relative; /* Adjust if necessary */
  z-index: 1; /* Adjust if necessary */
}

.cd-title-3 {
  font-size: 48px;
}

.dd-card-pool-fill1 {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  gap: 10px;
  grid-auto-flow: dense;
  /* margin-right: 18px; */
  margin: 0px 18px 18px 18px;
  opacity: 1;
  /* max-height: 2000px; */
  transition: all 0.5s;
}

.dd-card-pool-fill2 {
  display: none;
}

.dd-count {
  width: 140px;
  height: 196.14px;
  background-color: #00000050;
  margin: 2.25px 0px;
  border-radius: 7px;
  overflow: hidden;
  position: absolute;
  border: 1px solid white;
  z-index: 998;
}

.dd-count:hover {
  display: none;
}

.dd-count2 {
  width: 140px;
  height: 196.14px;
  background-color: #00000050;
  margin: 0 0 0 10px;
  border-radius: 7px;
  overflow: hidden;
  position: absolute;
  border: 1px solid white;
  z-index: 998;
}

.cardTip {
  max-width: 30vw;
}

.noBackScroll {
  background-color: transparent;
  height: auto;
  /* overflow: hidden; */
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  position: relative;
  z-index: 1;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.noBackScroll::-webkit-scrollbar {
  display: none;
}

.playAreaCentered {
  width: 100vw;
}

.mainPage {
  max-width: 2500px;
}

.cardPoolColumn {
  display: flex;
  flex-direction: column;
}

.padding-5 {
  padding: 5px;
}

.padding-8-5 {
  padding: 8px 5px;
}

.ttsLogo {
  height: 170px;
  margin: 20px;
}

.ttsText {
  margin: 20px;
  font-size: 60px;
}

.ttsLogoContainer {
  width: fit-content;
  display: flex;
  justify-content: center;
}

.ttsBanner {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to bottom, #c848329f, #f7a233), url("../public/ttsback.png");
  background-position: center;
  background-size: cover;
  overflow: hidden;
  height: 200px; /* Adjust this value as needed to control the card height */
  width: 100%;
  outline: 1px white solid;
  border-radius: 7px;
}

.discordBanner {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, #5865f22c, #5865f2c4, #323986), url("../public/discordSplash.png");
  background-position: center;
  background-size: cover;
  overflow: hidden;
  height: 200px; /* Adjust this value as needed to control the card height */
  width: 100%;
  outline: 1px white solid;
  border-radius: 7px;
}

.discordLogoContainer {
  width: fit-content;
  display: flex;
  justify-content: space-around;
}

.fix {
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 1330px) {
  .midNone {
    display: none;
  }

  .midInline {
    display: inline;
  }

  .midDisplay {
    display: block;
  }

  .midFlexContent {
    display: flex;
    justify-content: center;
  }

  .mid-cards-page-card-list {
    display: grid;
    grid-template-columns: repeat(4, min-content);
    gap: 1rem; /* Add any gap you want between the cards */
    justify-content: center;
  }

  .ttsText {
    font-size: 45px;
  }
}

@media screen and (max-width: 1073px) {
  .midNavDisplay {
    display: block;
  }

  .navHidden2 {
    display: none;
  }

  .nav-main {
    height: 66px;
    align-items: center;
  }

  .navbar-menu {
    display: block;
    top: 66px;
    padding: 0;
  }

  .nav-item {
    font-size: 20px;
    font-weight: 400;
    padding: auto;
    float: none;
    overflow: hidden;
    background-color: rgb(44, 41, 56);
    width: 100vw;
    outline: .5px solid white;
  }

  .nav-item2 {
    display: flex;
    list-style-type: none;
  }

  .navbar-menu-item {
    margin: 20px;
    padding: 0;
  }

  .nav-dropdown-content {
    background-color: rgba(80, 74, 102, 0.623);
    position: relative;
    width: 100%;
    z-index: 1;
    top: auto;
  }

  .dropdown-select {
    display: flex;
    justify-content: left;
  }

  .nav-dropdown-item {
    margin-left: 20px;
    transition: all .5s;
  }

  .nav-dropdown-item:hover {
    margin-left: 35px;
    transition: all .5s;
  }

  .navbar-select {
    justify-content: left;
  }

  .navbar-select2 {
    display: flex;
    justify-content: center;
    background-color: rgb(123, 114, 158);
    width: 50vw;
    outline: 1px solid white;
    transition: all 1s;
  }

  .navbar-select2:hover {
    background-color: red;
    transition: all 1s;
  }

  .dropdown-select:hover {
    background-color: rgba(255, 0, 0, 0);
  }

  .minimize {
    top: 66px;
    max-height: 0;
    max-width: 0;
    opacity: 0;
    transition: all .5s;
  }

  .maximize {
    top: 66px;
    max-height: 1333px;
    max-width: 1333px;
    opacity: 1;
    transition: all .5s;
  }

  .midNavNone {
    display: none;
  }
}

@media screen and (max-width: 1000px) {
  .list-page-fill {
    margin: 20px auto;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    /* gap: 1rem; */
  }

  .card-page-fill {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 7px;
  }

  .cards-page-card-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 7px;
  }

  .cards-page-card-list5 {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem; /* Add any gap you want between the cards */
  }

  .cards-page-card-list6 {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem; /* Add any gap you want between the cards */
    justify-items: center;
  }

  .cards-page-card-list7 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem; /* Add any gap you want between the cards */
    justify-items: center;
  }

  .card-list2 {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem; /* Add any gap you want between the cards */
  }

  .deck-row-card-list2 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem; /* Add any gap you want between the cards */
  }

  .decks-page-card-list2 {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem; /* Add any gap you want between the cards */
  }

  .media-margin-top-none {
    margin-top: 0;
  }

  .media-margin-top-10 {
    margin-top: 10px;
  }

  .media-margin-top-40 {
    margin-top: 40px;
  }

  .media-margin-bottom-none {
    margin-bottom: 0;
  }

  .media-margin-bottom-20 {
    margin-bottom: 20px;
  }

  .media-card-image-wrapper {
    position: relative;
    overflow: hidden;
    height: 220px; /* Adjust this value as needed to control the card height */
  }

  .media-card-image-wrapper2 {
    position: relative;
    overflow: hidden;
    height: 200px; /* Adjust this value as needed to control the card height */
  }

  .media-card-image-clip {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin-top: -20%;
    height: 50%; /* Adjust this value to show the desired portion of the image */
    background-color: black; /* Adjust the background color if needed */
  }

  .media-card-image-clip2 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin-top: -10%;
    height: 50%; /* Adjust this value to show the desired portion of the image */
  }

  .media-card-image-clip3 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin-top: -45%;
    height: 50%; /* Adjust this value to show the desired portion of the image */
    background-color: black; /* Adjust the background color if needed */
  }

  .media-card-image2 {
    width: 100%;
    height: auto;
    object-fit: cover;
    /* filter: brightness(20%); */
  }

  .media-card-image3 {
    width: 100%;
    height: auto;
    object-fit: cover;
    filter: brightness(20%);
  }

  .button-fill {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    gap: auto;
    grid-auto-flow: dense;
    align-items: center;
  }

  .top-row {
    width: 100%;
  }

  .top-row-card-list2 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem; /* Add any gap you want between the cards */
  }

  .dcbsearch-large {
    width: 366px;
    height: 37px;
  }

  .dcbsearch-x-large {
    width: 366px;
    height: 37px;
  }

  .dcbsearch-x-x-large {
    width: 366px;
    height: 37px;
  }

  .dcbsearch-switch {
    width: 178px;
    height: 37px;
  }

  .media-button{
    width: 120px;
    margin-left: 35px;
  }

  .media-center {
    text-align: center;
  }

  .between-space {
    display: inline;
  }

  .media-vert-30 {
    margin-top: 30px;
  }

  .blackfooter {
    background: black;
    height: 40%;
    display: flex;
    justify-content: bottom;
    z-index: 1;
    opacity: .80;
  }

  .media-title {
    font-size: x-large;
    margin: 0 10px 0 10px;
  }

  .white-space {
    max-width: 98%;
    margin: auto;
  }

  .media-display {
    display: block;
    justify-items: initial;
    justify-content: initial;
    justify-self: initial;
  }

  .cd-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin: auto auto 8px auto;
  }

  .Staunch {
    width: 100%;
    margin: 0 0 0 0;
    background: none;
    background-color: rgb(42, 168, 115);
    border: 7px solid rgb(3, 131, 78);
    border-radius: 7px;
    outline: 1px solid white;
  }

  .Power {
    width: 100%;
    margin: 0 0 0 0;
    background: none;
    background-color: rgb(124, 19, 33);
    border: 7px solid rgb(255, 0, 43);
    border-radius: 7px;
    outline: 1px solid white;
  }

  .Unity {
    width: 100%;
    margin: 0 0 0 0;
    background: none;
    background-color: rgb(82, 96, 194);
    border: 7px solid rgb(28, 40, 78);
    border-radius: 7px;
    outline: 1px solid white;
  }

  .Canny {
    width: 100%;
    margin: 0 0 0 0;
    background: none;
    background-color: rgb(101, 56, 131);
    border: 7px solid rgb(60, 26, 68);
    border-radius: 7px;
    outline: 1px solid white;
  }

  .NoClass {
    width: 100%;
    margin: 0 0 0 0;
    background-size: 100%;
  }

  .bigStaunch {
    width: 100%;
    margin: 0 0 0 0;
    background: none;
    background-color: rgb(42, 168, 115);
    border: 7px solid rgb(3, 131, 78);
    border-radius: 7px;
    outline: 1px solid white;
  }

  .bigPower {
    width: 100%;
    margin: 0 0 0 0;
    background: none;
    background-color: rgb(124, 19, 33);
    border: 7px solid rgb(255, 0, 43);
    border-radius: 7px;
    outline: 1px solid white;
  }

  .bigUnity {
    width: 100%;
    margin: 0 0 0 0;
    background: none;
    background-color: rgb(82, 96, 194);
    border: 7px solid rgb(28, 40, 78);
    border-radius: 7px;
    outline: 1px solid white;
  }

  .bigCanny {
    width: 100%;
    margin: 0 0 0 0;
    background: none;
    background-color: rgb(101, 56, 131);
    border: 7px solid rgb(60, 26, 68);
    border-radius: 7px;
    outline: 1px solid white;
  }

  .bigNoClass {
    width: 100%;
    margin: 0 0 0 0;
  }

  .bigNoClassCreate {
    width: 100%;
    /* margin: 0 0 0 0; */
  }

  .bigStaunch4 {
    width: 100%;
    color: aliceblue;
    margin: 4% 0 0 0;
    background: none;
    background-color: rgb(36, 104, 76);
    border: 7px solid rgb(28, 196, 126);
    border-radius: 7px;
    outline: 1px solid white;
  }

  .bigPower4 {
    width: 100%;
    color: aliceblue;
    margin: 4% 0 0 0;
    background: none;
    background-color: rgb(124, 19, 33);
    border: 7px solid rgb(255, 0, 43);
    border-radius: 7px;
    outline: 1px solid white;
  }

  .bigUnity4 {
    width: 100%;
    color: aliceblue;
    margin: 4% 0 0 0;
    background: none;
    background-color: rgb(82, 96, 194);
    border: 7px solid rgb(28, 40, 78);
    border-radius: 7px;
    outline: 1px solid white;
  }

  .bigCanny4 {
    width: 100%;
    color: aliceblue;
    margin: 4% 0 0 0;
    background: none;
    background-color: rgb(101, 56, 131);
    border: 7px solid rgb(60, 26, 68);
    border-radius: 7px;
    outline: 1px solid white;
  }

  .bigFaith4 {
    width: 100%;
    color: aliceblue;
    margin: 4% 0 0 0;
    background: none;
    background-color: rgba(156, 118, 13, 0.87);
    border: 7px solid rgb(240, 190, 28);
    border-radius: 7px;
    outline: 1px solid white;
  }


  .margin-left-3P {
    margin-left: 0;
  }

  .media-card-image {
    position: fixed;
    z-index: 200;
    width: 338px;
    margin: 2.5% 0px 0px 0%;
    border-radius: 17px;
    overflow: hidden;
    top: 50%;
    left: 50%;
    display: none;
    transform: translate(-50%, -50%);
    border: 1px solid white;
  }

  .builder-card2:hover {
    position: static;
    top: auto;
    left: auto;
    transform: none;
  }

  .cd-related-card {
    width: 90%;
    max-width: 400px;
    border-radius: 4%;
    overflow: hidden;
    margin: 0px 0px 10px 00px;
    border: 1px solid white;
    transition: none;
  }

  .cd-related-card:hover {
    width: 90%;
    border-radius: 4%;
    position: static; /* or any other appropriate position value */
    top: auto; /* Reset the top property */
    left: auto; /* Reset the left property */
    transform: none; /* Reset the transform property */
  }

  .media-flex-center {
    display: flex;
    justify-content: center;
  }

  body {
    background-size: 100%, 60%;
  }

  .wide40 {
    width: 100%;
  }

  .wide55 {
    width: 100%;
  }

  .wide100 {
    width: 100%;
  }

  .heightAuto {
    height: auto;
  }

  .margin-top-63 {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .media-bot-30 {
    margin-bottom: 30px;
  }

  .wide400p {
    width: 400px
  }

  .none {
    display: none;
  }

  .stat-item {
    margin: auto;
  }

  .scrollable2 {
    height: 56.5vh;
  }

  .inScrollable {
    margin-top: auto;
    position: relative; /* Make the container a positioning context */
    width: 100%; /* Ensure the image container takes the full width of the scrollable div */
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* .cardpool {
    height: auto;
  } */

  .cardpool2-ro {
    margin: 0;
    background-color: rgba(80, 42, 170, 0.315);
    border: 2px solid rgb(68, 17, 206);
    border-radius: 5px;
    height: 70vh;
    width: 100%;
    outline: 1px solid white;
    transition: all 0.5s;
  }

  .no-cardpool-ro {
    margin: 0;
    background-color: rgba(117, 90, 182, 0.315);
    border: 2px solid rgb(68, 17, 206);
    border-radius: 5px;
    width: 100%;
    outline: 1px solid white;
    transition: all 0.5s;
  }

  .no-cardpool2-ro {
    margin: 0;
    background-color: rgba(117, 90, 182, 0.315);
    border: 2px solid rgb(68, 17, 206);
    border-radius: 5px;
    height: 95px;
    width: 100%;
    outline: 1px solid white;
    transition: all 0.5s;
  }

  .no-cardpool3-ro {
    margin: 0;
    background-color: rgba(117, 90, 182, 0.315);
    border: 2px solid rgb(68, 17, 206);
    border-radius: 5px;
    height: auto;
    width: 100%;
    outline: 1px solid white;
    transition: all 0.5s;
  }

  .table200 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    margin: 0;
    border: 1px solid;
    border-color: var(--color-body);
    padding: 0px;
  }

  .dark .table200 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    margin: 0;
    border: 1px solid;
    border-color: var(--color-body);
    padding: 0px;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .tableText {
    border: 1px solid;
    border-color: var(--color-body);
    padding: 0px;
    max-width: var(--window-width200);
    background-color: rgba(255, 255, 255, 0.7);
  }

  .dark .tableText {
    border: 1px solid;
    border-color: var(--color-body);
    padding: 0px;
    max-width: var(--window-width200);
    background-color: rgba(0, 0, 0, 0.4);
  }

  .text-table {
    font-weight: 600;
    margin: 5px;
  }

  .text-table-2 {
    font-weight: 600;
    margin: 10px;
  }

  .catTable {
    margin-left: 0;
  }

  .cat-label {
    text-align: left;
    margin: 2px 5px 10px 5px;
    font-weight: 700;
  }

  .fullTableBorder {
    border: 1px solid;
    border-color: var(--color-body);
    width: auto;
  }

  .newsSection {
    height: 55px;
    margin: 0 -10px 0 -20px;
  }

  .newsSection2 {
    margin: 10px 2.5%;
  }

  .newsText2 {
    font-size: 20px;
    text-align: center;
  }

  .newsText3 {
    font-size: 20px;
    text-align: center;
    margin: 15px 0;
    font-weight: 500;
  }

  .newsText4 {
    text-align: center;
  }

  .newsText5 {
    text-align: center;
  }

  .no-wrap {
    white-space: nowrap;
  }

  .newsImageContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 1%;
    margin: 10px;
  }

  .newsImageContainer2 {
    display: flex;
    justify-content: space-around;
  }

  .newsImage {
    max-height: 450px;
    max-width: 100%;
    margin: 2.25px auto;
    border-radius: 14px;
    overflow: hidden;
    /* border: 1px solid white; */
  }

  .media-hover-center {
    top: 0%;
    left: 0%;
  }

  .media-h1-h2 {
    font-size: 1.5rem; /* Adjust the font size as per your requirement */
    font-weight: 500;
  }

  .media-h1-h2-box {
    margin-top: 0;
  }

  .media-inline {
    display: inline;
  }

  .login {
    width: 100%;
  }

  .login input {
    width: 60vw;
  }

  .account-option-items {
    margin-top: 20px;
    width: 100%;
  }

  .flex-left-media-center {
    display: flex;
    justify-content: center;
  }

  .neg-margin-top-10 {
    margin-top: -10px;
  }

  .media-flex-column-full {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .media-flex-column-items {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
  }

  .bigStaunch5 {
    width: 100%;
    color: aliceblue;
    margin: 2% 0 2% 0;
    background: none;
    background-color: rgb(36, 104, 76);
    border: 7px solid rgb(28, 196, 126);
    border-radius: 7px;
    outline: 1px solid white;
  }

  .bigPower5 {
    width: 100%;
    color: aliceblue;
    margin: 2% 0 2% 0;
    background: none;
    background-color: rgb(124, 19, 33);
    border: 7px solid rgb(255, 0, 43);
    border-radius: 7px;
    outline: 1px solid white;
  }

  .bigUnity5 {
    width: 100%;
    color: aliceblue;
    margin: 2% 0 2% 0;
    background: none;
    background-color: rgb(82, 96, 194);
    border: 7px solid rgb(28, 40, 78);
    border-radius: 7px;
    outline: 1px solid white;
  }

  .bigCanny5 {
    width: 100%;
    color: aliceblue;
    margin: 2% 0 2% 0;
    background: none;
    background-color: rgb(101, 56, 131);
    border: 7px solid rgb(60, 26, 68);
    border-radius: 7px;
    outline: 1px solid white;
  }

  .newsRow {
    max-height: 250px;
    overflow-y: scroll;
    background-color: transparent; /* Ensure it's not white */
    position: relative; /* Adjust if necessary */
    z-index: 1; /* Adjust if necessary */
  }

  .account-scrollable {
    margin: 0% 0% 0% .5%;
    height: 473px;
    overflow-x: hidden;
    overflow-y: auto;
    border: 1px solid rgba(0, 0, 0, 0.212);
    background-color: rgba(15, 0, 50, 0.315);
    border-radius: 2px;
    outline: 1px solid rgba(255, 255, 255, 0.3);
    position: relative; /* Adjust if necessary */
    z-index: 1; /* Adjust if necessary */
  }

  .media-builder-width {
    width: 380px;
  }

  .media-space-around {
    display: flex;
    justify-content: space-around;
  }

  .cd-title-3 {
    font-size: 35px;
  }

  .relative {
    position: relative;
  }

  .dd-count:hover {
    display: flex;
  }

  .dd-card-pool-fill1 {
    display: none;
  }

  .dd-card-pool-fill2 {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    gap: 10px;
    grid-auto-flow: dense;
    /* margin-right: 18px; */
    margin: 0px 18px 18px 18px;
    opacity: 1;
    /* max-height: 2000px; */
    transition: all 0.5s;
  }

  .newsItem {
    outline: none;
    overflow: hidden;
  }

  .cardTip {
    max-width: 90vw;
  }

  .media-margin-bottom-n15 {
    margin-bottom: -15px;
  }

  .margin-top-63 {
    margin-top: 0px;
  }

  .cd-card {
    max-width: 480px;
    border-radius: 4%;
    overflow: hidden;
    border: 1px solid white;
  }

  .setMinContent {
    width: min-content;
  }

  .cdMinContent {
    max-width: max-content;
    display: block;
  }

  .ttsLogo {
    height: 90px;
    margin: 20px;
  }

  .ttsText {
    font-size: 35px;
  }

  .ultra:hover {
    transform: none;
  }
}

@media screen and (max-height: 700px) {
  .panel-card {
    display: none;
  }
  .infoPanelContent {
    height: 95%;
    /* margin-top: -5px; */
  }
  .mediaInfoPanel {
    display: flex;
    height: var(--window-height2);
    flex-direction: column;
    justify-content: center;
  }
  .infoPanelNone {
    display: none;
  }
  .panel-text-box {
    height: 80%;
    width: 400px;
  }
}

@media (hover: none) and (pointer: coarse) {
  .noHover {
    display: none;
  }
}
