.drag-scroll {
    overflow-x: auto;
    cursor: grab;
    display: flex;
    position: relative;
    z-index: 1;
  }

  .drag-scroll.active {
    cursor: grabbing;
  }

  /* Hide scrollbars if needed */
  .drag-scroll::-webkit-scrollbar {
    display: none;
  }

  .drag-scroll {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
